import './css/generalcssproduct.css';
import { Link } from 'react-router-dom';

import vita1 from './images/vita/vita1.jpg';
import vita2 from './images/vita/vita2.jpg';
import vita3 from './images/vita/vita3.jpg';
import vita4 from './images/vita/vita4.jpg';
import vita5 from './images/vita/vita5.jpg';
import vita6 from './images/vita/vita6.jpg';
import vita7 from './images/vita/vita7.jpg';
import vita8 from './images/vita/vita8.jpg';
import vita9 from './images/vita/vita9.jpg';
import vita10 from './images/vita/vita10.jpg';
import vita11 from './images/vita/vita11.jpg';
import vita12 from './images/vita/vita12.jpg';

export const Vita = () => {
    return(

        <div className='products'>
            <h1 className='all-products'>Vită</h1>
            <div className='align-products'>
                <div className='line-product-title'></div>
                <h2>Secretul unui burger perfect</h2>
                <div className='line-product-title'></div>
            </div>
            <p className='paragraph-align-product'>Carnea de vită perfectă pentru burgeri savuroși și originali!</p>
            <div id='product-section'>
                <div className='section-product-1'>
                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Cheeseburger Fresh</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita1} alt='all-item-products' />
                        </Link>
                    </div>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Greek Style Wrap</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita2} alt='all-item-products' />
                        </Link>
                    </div>
                    

                    </div>

                    <div className='section-product-1'>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Quarter Pounder®</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita3} alt='all-item-products' />
                        </Link>
                    </div>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Big Mac®</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita4} alt='all-item-products' />
                        </Link>
                    </div>

                    </div>

                    <div className='section-product-1'>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Fresh Deluxe</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita5} alt='all-item-products' />
                        </Link>
                    </div>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Hamburger</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita6} alt='all-item-products' />
                        </Link>
                    </div>

                    </div>

                    <div className='section-product-1'>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Big Tasty™</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita7} alt='all-item-products' />
                        </Link>
                    </div>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Cheeseburger</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita8} alt='all-item-products' />
                        </Link>
                    </div>

                    </div>

                    <div className='section-product-1'>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'> <h1 className='color-title-product'>Dublu Cheeseburger</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita9} alt='all-item-products' />
                        </Link>
                    </div>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Hamburger fără gluten</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita10} alt='all-item-products' />
                        </Link>
                    </div>

                    </div>

                    <div className='section-product-1'>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'><h1 className='color-title-product'>Cheeseburger fără gluten</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita11} alt='all-item-products' />
                        </Link>
                    </div>

                    <div className='grid-name'>
                        <div className='aliniere-product'>
                        <Link to='/mcpuisorfresh' className='no-underline-title-products'> <h1 className='color-title-product'>Double Cheeseburger fără gluten</h1></Link>
                        <Link to='/mcpuisorfresh'><button className='color-button-product'>Află mai multe</button></Link>
                        </div>
                        <Link to='/mcpuisorfresh' className='size-link-of-img'>
                        <img src={vita12} alt='all-item-products' />
                        </Link>
                    </div>

                    </div>
                    
            </div>


        </div>
        
    )
}